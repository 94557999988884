<template>
  <v-app-bar class="white--text top-bar" height="56px" app>
    <v-app-bar-nav-icon>
      <v-btn
        text
        color="white"
        @click="toggleMenu(!isMenuOpen)"
        id="menu-button"
      >
        <v-icon>{{ mdiMenu }}</v-icon>
      </v-btn>
    </v-app-bar-nav-icon>
    <v-toolbar-title>
      {{ title }}
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mdiMenu } from "@mdi/js";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "TopBar",
  props: {
    title: String,
  },
  computed: {
    ...mapGetters(["isMenuOpen"]),
  },
  data() {
    return {
      mdiMenu,
    };
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    toggleMenu(showMenu) {
      this.setMenuState(showMenu);
    },
  },
};
</script>
