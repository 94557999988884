var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { staticClass: "white--text top-bar", attrs: { height: "56px", app: "" } },
    [
      _c(
        "v-app-bar-nav-icon",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "white", id: "menu-button" },
              on: {
                click: function ($event) {
                  return _vm.toggleMenu(!_vm.isMenuOpen)
                },
              },
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiMenu))])],
            1
          ),
        ],
        1
      ),
      _c("v-toolbar-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }